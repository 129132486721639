import React from "react";

import Layout from "../../components/layout";
import GrfDashboards from "../../components/grfDashboards";

import { useSettings } from "../../hooks";
import Links from "../../utils/links";

const AnalysisApprovedPage = () => {
    const dashboardType = () => {
        const { grfDashboard } = useSettings();
        const { approved } = grfDashboard;
        return [
            { name: "By Lamppost", url: approved.byLamppost },
            { name: "By Lamppost (Avg)", url: approved.byLamppostAvg },
            { name: "By Pollutant", url: approved.byPollutant },
            { name: "By Pollutant (Avg)", url: approved.byPollutantAvg },
            { name: "Compare with Raw", url: approved.compare },
            { name: "Compare with Raw (Avg)", url: approved.compareAvg }
        ];
    };
    return (
        <Layout
            link={Links.DataAnalysis}
            seoTitle="Approved Data Analysis"
            mainDivCss=""
        >
            <GrfDashboards
                dashboardType={dashboardType()}
                link={Links.DaApproved}
            />
        </Layout>
    );
};

export default AnalysisApprovedPage;
